import React from "react"
import { CreateLocalLinkMenu } from "../../../utils"
import UniversalLink from "./../UniversalLink"
import cn from "classnames"

const MenuItem = ({ menuItem, wordPressUrl, closeMenu, pathname }) => {
    const linkClassPath = `page-${menuItem.label ? menuItem.label.toLowerCase() : ""}`
    const currentPathClass = `page-${pathname.split('/')[1].replaceAll("/", "")}`

    return (
        <UniversalLink
            className={cn(
                linkClassPath === currentPathClass ? `active` : ``,
            )}
            to={CreateLocalLinkMenu(menuItem, wordPressUrl)}
            onClick={() => {
                if (closeMenu) {
                    closeMenu()
                }
            }}>
            <span>{menuItem.label}</span>
        </UniversalLink>
    )
}

export default MenuItem
