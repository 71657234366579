import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import { useStaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import Header from "../components/elements/header/Header"
import "./Layout.scss"
import Menu from "../components/elements/menu/Menu"
import Footer from "../components/elements/footer/Footer"
import Transition from "../components/transition"
import { Location } from "@reach/router"
import { PagePositionContext, PageColorContext } from "./layout-context"
import { createGlobalStyle } from "styled-components"
import LazyLoad from "vanilla-lazyload"

const GlobalStyles = createGlobalStyle`
  html {
    --current-bg-color ${props => (`var(--color-${props.currentColorIndex});`)}
    --current-accent-color-inverse: ${props => (`var(--color-${props.currentColorIndex >= props.colors.length - 1 ? 0 : parseInt(props.currentColorIndex) + 1});`)} ${props => props.colors.map((color, index) => (`--color-${index} : ${color.color};`))}
  }

  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 4px;
    -webkit-box-shadow: none !important;
    background-color: black !important;
    transition: width 0.2s ease;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 2px solid var(--current-accent-color-inverse);
    border-radius: 0;
    background: var(--current-accent-color-inverse);
    width: 8px;
    transition: all .3s;
    margin-left: -8px;
    -webkit-box-shadow: none;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: var(--current-accent-color-inverse);
  }
`
const Layout = ({ children }) => {
    const [pagePositionContext, setPagePositionContext] = useState(0)
    const [pageColorContext, setPageColorContext] = useState(() => typeof localStorage !== "undefined" ? localStorage.getItem("currentColor") : 0)

    useEffect(() => {
        // Only initialize it one time for the entire application
        if (document && !document.lazyLoadInstance) {
            document.lazyLoadInstance = new LazyLoad({ elements_selector: ".lazy" })
        }
    }, [])

    const data = useStaticQuery(graphql`
        query LayoutQuery {
            wp {
                generalSettings {
                    title
                }
                themeOptions {
                    themeColors {
                        colors {
                            color
                            naam
                        }
                    }
                }
            }
        }
    `)

    const {
        wp: { generalSettings, themeOptions: { themeColors: { colors } }, seo },
    } = data

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.clear()
        // eslint-disable-next-line no-console
        console.log(
            "%c👨‍🚀  Designed and coded by%c UNDEFINED ",
            "font-size:10px; font-weight: 500; color:#000; background-color:#fff; padding:5px; padding-right:2px ;",
            "font-size:10px; font-weight: 700; color:#000; background-color:#fff; padding:5px; padding-left:0px;",
        )
        // eslint-disable-next-line no-console
        console.log(
            "%c" + "🔗 weareundefined.be 🌍",
            "color:blue;font-size:1; background-color:#fff; padding:5px; padding-right:2px;",
        )
    }, [])

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add("loaded")
        }, 100)
    })

    useEffect(() => {
        try {
            if (!!pageColorContext && colors[pageColorContext]) {
                document.body.style.backgroundColor = colors[pageColorContext].color
                document.getElementById("menu").style.backgroundColor = colors[pageColorContext].color
                document.getElementById("mobilemenu").style.backgroundColor = colors[pageColorContext].color
            }
        } catch (e) {
            console.error(e)
        }
    }, [pageColorContext])

    return (
        <SEOContext.Provider value={{ global: seo }}>
            <Location>
                {({ location }) => (
                    <ParallaxProvider>
                        <PagePositionContext.Provider value={[pagePositionContext, setPagePositionContext]}>
                            <PageColorContext.Provider value={[pageColorContext, setPageColorContext]}>
                                <GlobalStyles colors={colors} currentColorIndex={pageColorContext} />
                                <Menu pathname={location.pathname} />

                                <Header
                                    siteTitle={generalSettings.title}
                                    pathname={location.pathname}
                                />

                                <Transition location={location}>{children}</Transition>
                                <Footer />
                            </PageColorContext.Provider>
                        </PagePositionContext.Provider>
                    </ParallaxProvider>
                )}
            </Location>
        </SEOContext.Provider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
