import loadable from "@loadable/component"
import React from "react"
import "./Header.scss"

const SlideTitle = loadable(() => import("../SlideTitle"))

const Header = ({ siteTitle, pathname }) => {
    return (
        <div className={"title-init"}>
            <SlideTitle pathname={pathname}>
                {siteTitle}
            </SlideTitle>
        </div>
    )
}

export default Header
