import React from "react"
import "./Footer.scss"

const Footer = () => (
    <footer className="footer">
        <div className="col">
            © {new Date().getFullYear()} NOORTJE PALMERS | MADE BY <a href="https://weareundefined.be">Undefined</a>
        </div>
    </footer>
)

export default Footer