import React, { useRef, useState } from "react"
import { StaticQuery, graphql } from "gatsby"

import MenuItem from "./MenuItem"
import ColorPick from "../colorpick/ColorPick"
import "./Menu.scss"
import useOnClickOutside from "../../../hooks/useOnClickOutside"

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MENU_QUERY = graphql`
    fragment MenuItem on WpMenuItem {
        id
        label
        url
        title
        target
    }

    {
        allWpMenuItem(filter: {locations: {eq: PRIMARY}}) {
            nodes {
                ...MenuItem
                connectedNode {
                    node {
                        __typename
                        uri
                        id
                        databaseId
                    }
                }
            }
        }
        wp {
            generalSettings {
                url
            }
        }
    }
`

const Menu = ({ pathname }) => {
    const [open, setMenuOpen] = useState(false)
    const mobileMenuRef = useRef()

    useOnClickOutside(mobileMenuRef, (event) => {
        let clickedOnClose = event.target.innerText === "MENU" || event.target.innerText === "CLOSE";
        // We need this check otherwise it gets fired twice and the menu will not close when clicking 'close'
        if (!clickedOnClose) {
            setMenuOpen(false)
        }
    })

    return (
        <StaticQuery
            query={MENU_QUERY}
            render={(data) => {
                if (data.allWpMenuItem) {
                    const menuItems = data.allWpMenuItem.nodes
                    const wordPressUrl = data.wp.generalSettings.url.replace(
                        "/wp",
                        "",
                    )

                    return (
                        <div className="nav-wrapper">
                            <div
                                ref={mobileMenuRef}
                                className={
                                    "menu-mobile " +
                                    (open === true ? "is-open" : "is-closed")
                                }
                                id="mobilemenu"
                            >
                                {menuItems &&
                                    menuItems.map((menuItem) => (
                                        <MenuItem
                                            closeMenu={() => setMenuOpen(false)}
                                            key={menuItem.id}
                                            menuItem={menuItem}
                                            pathname={pathname}
                                            wordPressUrl={wordPressUrl}
                                        />
                                    ))}
                            </div>
                            <nav className="nav-main" id="menu">
                                <a
                                    role="button"
                                    className="mobile-menu-text"
                                    onClick={() => setMenuOpen(!open)}
                                >
                                    {open ? (
                                        <span>Close</span>
                                    ) : (
                                        <span>Menu</span>
                                    )}
                                </a>
                                <div className="menu-desktop">
                                    {menuItems &&
                                        menuItems.map((menuItem) => (
                                            <MenuItem
                                                key={menuItem.id}
                                                menuItem={menuItem}
                                                pathname={pathname}
                                                wordPressUrl={wordPressUrl}
                                            />
                                        ))}
                                </div>
                                <ColorPick />
                            </nav>
                        </div>
                    )
                }
                return null
            }}
        />
    )
}

export default Menu
