import React, { useContext, useEffect } from "react"
import "./ColorPick.scss"
import { graphql, useStaticQuery } from "gatsby"
import { PageColorContext } from "../../../layouts/layout-context"
import cn from "classnames"

const ColorPick = () => {
    const [pageColorContext, setPageColorContext] = useContext(PageColorContext)

    useEffect(() => {
        localStorage.setItem("currentColor", pageColorContext)
    }, [pageColorContext])

    const data = useStaticQuery(graphql`
        {
            wp {
                themeOptions {
                    themeColors {
                        colors {
                            color
                            naam
                        }
                    }
                }
            }
        }
    `)
    const {
        wp: {
            themeOptions: {
                themeColors: { colors },
            },
        },
    } = data

    return (
        <ul className="color-pick">
            {colors && colors.map((color, index) => (
                <li
                    role="button"
                    key={index}
                    data-color={color.color}
                    aria-label={`Verander de achtergrond naar ${color.naam}`}
                    className={cn(
                        "color-item",
                        parseInt(pageColorContext) === parseInt(index) && "active"
                    )}
                    tabIndex={index}
                    onClick={() => setPageColorContext(`${index}`)}
                    style={{
                        backgroundColor: color.color,
                    }}
                >&nbsp;</li>
            ))}
        </ul>
    )
}

export default ColorPick
