import React, { useContext, useRef, useState } from "react"
import {
    Transition as ReactTransition,
    SwitchTransition,
    CSSTransition,
} from "react-transition-group"
import "./transition.scss"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { PositionStore } from "../utils"
import { PagePositionContext } from "../layouts/layout-context"


const Transition = ({ children, location }) => {
    const positionsStore = PositionStore()
    const pageRef = useRef()
    const [pagePositionContext, setPagePositionContext] = useContext(PagePositionContext)

    useScrollPosition(
        ({ currPos }) => {
            setPagePositionContext(currPos.y)
            return positionsStore.setElementPosition(currPos)
        },
        [],
        pageRef,
        false,
        100,
    )

    const onEnter = () => {
        setPagePositionContext(0);
        return positionsStore.setElementPosition({ x: 0, y: 0 })
    }

    const onExit = (node) => {
        node.style.position = "fixed"
        node.style.top = positionsStore.getElementY() + "px"
    }

    return (
        <SwitchTransition component={null}>
            <CSSTransition
                key={location.pathname}
                appear={true}
                timeout={{
                    enter: 0,
                    exit: 500,
                }}
                onEnter={onEnter}
                onExit={onExit}
                classNames={"page-transition"}
            >
                <div ref={pageRef} className="transition-container">
                    {children}
                </div>
            </CSSTransition>
        </SwitchTransition>
    )
}

export default Transition
