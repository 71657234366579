/**
 * Parses a menu item object and returns Gatsby-field URI.
 *
 * @param {object} menuItem a single menu item
 * @param wordPressUrl
 * @param blogURI
 */

import { useRef, useState } from "react"
import { extract } from "oembed-parser"

export const CreateLocalLinkMenu = (menuItem, wordPressUrl, blogURI = "blog/") => {
    const { url, connectedNode } = menuItem

    if (url === "#") {
        return null
    }
    /**
     * Always want to pull of our API URL.
     */
    let newUri = url.replace(wordPressUrl, "")

    /**
     * If it's a blog link, respect the users blogURI setting.
     */
    if (connectedNode && connectedNode.__typename === "WpPost") {
        newUri = blogURI + newUri
    }

    return newUri
}

export const CreateLocalLink = (url, wordPressUrl, blogURI) => {

    if (url === "#") {
        return null
    }
    /**
     * Always want to pull of our API URL.
     */
    let newUri = url.replace(wordPressUrl, "")

    return blogURI + newUri
}

export const getRandom = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const PositionStore = () => {
    const [renderCount, triggerReRender] = useState(0)
    const elementPosition = useRef({ x: 0, y: 0 })
    const viewportPosition = useRef({ x: 0, y: 0 })
    let throttleTimeout = null

    const getPos = (el, axis) => Math.round(el.current[axis])

    const setPos = (el, pos) => {
        el.current = pos
        if (throttleTimeout !== null) return
        // Only re-render the component every 0.1s
        throttleTimeout = setTimeout(() => triggerReRender(renderCount + 1), 300)
    }

    return {
        getElementX: () => getPos(elementPosition, "x"),
        getElementY: () => getPos(elementPosition, "y"),
        getViewportX: () => getPos(viewportPosition, "x"),
        getViewportY: () => getPos(viewportPosition, "y"),
        setElementPosition: pos => setPos(elementPosition, pos),
        setViewportPosition: pos => setPos(viewportPosition, pos),
        renderCount,
    }
}

function makeObservable(target) {
    let listeners = [] // initial listeners can be passed an an argument aswell
    let value = target

    function get() {
        return value
    }

    function set(newValue) {
        if (value === newValue) return
        value = newValue
        listeners.forEach((l) => l(value))
    }

    function subscribe(listenerFunc) {
        listeners.push(listenerFunc)
        return () => unsubscribe(listenerFunc) // will be used inside React.useEffect
    }

    function unsubscribe(listenerFunc) {
        listeners = listeners.filter((l) => l !== listenerFunc)
    }

    return {
        get,
        set,
        subscribe,
    }
}

export function getRandomClass(prefix) {
    return prefix + getRandom(1, 8)
}